.navbar {
    position: absolute;
    width: 100%;
    height: 9vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
    margin-right: 1.8vw;
    padding-top: 10vh;
    padding-right: 5vw;
    padding-left: 5vw;
}

.navbar-text, .navbar-text-active {
    font-family: Outfit;
    color: black;
}

/* flex container containing the OptiGenix logo, as well as the OptiGenix text next to the logo */
.navbar-left {
    display: flex;
    align-items: center;
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1rem;
    font-weight: 500;
}

.hamburger {
    display: none;
}

@media screen and (max-width: 1240px) {
    .hamburger {
        display: block;
    }
    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition:0.4s;
        z-index: -1;
        background: white;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }
    
    .nav-menu a {
        font-size: 2rem;
    }
    .nav-menu .waitlist-button-active {
        padding-right: 2.5vw;
        padding-left: 2.5vw;
    }
}

/* OptiGenix text */
.opti {
    text-align: left;
    font-family: Outfit;
    font-size: 2.0rem;
    margin-left: 0.5vw;
}

@media (max-width: 750px) {
    .opti {
        margin-left: 1vw;
    }
}

/* OptiGenix logo */
.logo {
    height: 6vh;   
    width: auto;
    max-width: 100%
}

/* Waitlist button*/
.waitlist-button-active, .waitlist-button {
    padding-top: 1.75vh;
    padding-right: 1.5vw;
    padding-bottom: 1.75vh;
    padding-left: 1.5vw;
    margin-top: 3vh;
    border-top-left-radius: 20vh;
    border-top-right-radius: 20vh;
    border-bottom-left-radius: 20vh;
    border-bottom-right-radius: 20vh;
    background-color: hsla(161.44329896907217, 44.70%, 57.45%, 1.00);
    opacity: 1;
    color: hsla(170, 0.00%, 100.00%, 1.00);
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
  }