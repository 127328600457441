

/* contains entire page */
.team-circles {
  position: relative;
  padding-top: 20vh;
  padding-right: 30px;
  padding-bottom: 80px;
  padding-left: 30px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: hsla(212.00000000000006, 38.46%, 92.35%, 1.00);
}

@media (max-width: 768px) {
  .team-circles {
    padding-top: 20vh;
    padding-right: 15px;
    padding-bottom: 60px;
    padding-left: 15px;
  }
}

/* contains heading, subheading, and team images + text below images */
.container {
  width: 100%;
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 992px) {
  .container {
    max-width: 728px;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: none;
  }
}

  /* heading at top of page */
.centered-heading {
  margin-bottom: 16px;
  font-family: Outfit;
  color: hsla(0, 0.00%, 0.00%, 1.00);
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}

/* subheading at top of page */
.centered-subheading {
  max-width: 530px;
  margin-right: auto;
  margin-left: auto;
  font-family: Outfit;
  color: hsla(0, 0.00%, 0.00%, 1.00);
  font-weight: 300;
  text-align: center;
}

/* contains team images + text below images */
.team-grid {
  display: grid;
  margin-top: 50px;
  grid-auto-columns: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 56px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
}

@media (max-width: 992px) {
  .team-grid {
    grid-column-gap: 40px;
  }
}

@media (max-width: 768px) {
  .team-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .team-grid {
    grid-template-columns: 1fr;
  }
}

/* contains image + text below image for one team member */
.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

/* image for one team member */

.team-image {
  width: 270px;
  height: 270px;
  margin-bottom: 24px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  object-fit: cover;
}

@media (max-width: 992px) {
  .team-image {
    width: 190px;
    height: 190px;
  }
}

@media (min-width: 1200px) {
  .team-image {
    width: 270px;
    height: 270px;
    max-height: none;
  }
}

/* text for team member's name */
.name-text {
  font-family: Outfit;
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
}

/* text for team member's position */
.position-text {
  display: block;
  font-family: Outfit;
  color: hsla(161.44329896907217, 44.70%, 57.45%, 1.00);
  font-size: 16px;
  font-weight: 600;
}

/* text for team member's description */
.description {
  font-family: Outfit;
  color: hsla(0, 0.00%, 0.00%, 1.00);
  font-size: 15px;
  font-weight: 300;
}