.product {
  padding-top: 20vh;
}
/* title of Product Page (we take the guess work out of...)*/
.title {
  margin-top: 9vh;
  font-family: Outfit;
  color: hsla(0, 0%, 0%, 1);
  font-size: 4rem;
  line-height: 80px;
  font-weight: 600;
  text-align: center;
  margin-right: 5vw;
  margin-left: 5vw;
}

@media (max-width: 500px) {
  .title {
    font-size: 3.5rem;
  }
}

@media (max-width: 400px) {
  .title {
    font-size: 3.0rem;
  }
}

/* bold text in title */
.bold-text {
  color: #62c3a5;
  text-decoration: line-through;
}

/* image detailing steps 1-4 of product creation */
.steps {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  width: 80vw; 
  max-width: 100%;
  height: auto;
}

/* contains product-square */
.product-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* contains product image, creates border around it */
.product-square {
  display: flex;
  margin-top: 3vh;
  margin-bottom: 4vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  justify-content: center;
  align-items: center;
  border-radius: 10vh;
  background-color: hsla(160, 0%, 100%, 1);
  box-shadow: -2px 2px 80px -20px hsla(162.1875, 45.07%, 72.16%, 1);
  outline-color: #555;
  outline-offset: 0px;
  outline-style: none;
  outline-width: 3px;
  color: hsla(161.44329896907217, 44.70%, 57.45%, 1);
  mix-blend-mode: normal;
  width: 70vw;
}

/* product image, showing what clients receive */
.product-img {
  width: 60vw;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2vh;
  margin-bottom: 2vh;
  display: block;
}

@media (max-width: 500px) {
  .product-square {
    width: 90vw;
  }
  .product-img {
    width: 80vw;
  }
  .steps {
    width: 90vw;
  }
}