/* contains entire footer */
.footer {
    padding-right: 4.6vw;
    padding-left: 4.6vw;
  }

/* sets padding for top section of footer */
.footer-top {
  padding-top: 16vh;
  padding-bottom: 16vh;
}

/* contains logo-container, list-of-links, and socials-container */
.footer-top-container {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
  }

/* contains optigenix logo and text */
.logo-container {
    margin-bottom: 1vh;
    max-width: 12vw;
    display: flex;
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease; 
    margin-right: auto;
    align-items: center;
}

.logo-container:hover {
    transform: scale3d(0.92, 0.92, 1.01);
}

/* optigenix logo in footer */
.footer-logo {
    max-width: 100%;
    width: auto;
    height: 6vh;
}

/* contains list of links to all site pages */
.list-of-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

/* text for each link in list-of-links */
.footer-text {
    font-family: Outfit;
    font-size: 1.2rem;
    padding-right: 1rem;
}

/* contains social media icons */
.socials-container {
    justify-content: center;
    align-items: center;
    margin-left: auto;
    display: flex;
    width: 10vw;
  }

/* corresponds to one social media icon that links to respective social media site*/
.social-icon {  
    width: 5vh;
    padding-right: 1vh;
    height: auto; 
}

/* sets dimensions for bottom section of footer (copyright section) */
.footer-bottom {
    padding-top: 3vh;
    padding-bottom: 3vh;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #eff0f6;
    text-align: center;
    margin-right: 3.5vh;
  }

  /* text in bottom section of footer */
  .footer-bottom-text {
    font-family: Outfit;
  }

  /* changes footer to be vertical instead of horizontal for small screens */
  @media (max-width: 1000px) {
    .footer-top-container {
        flex-direction: column;
    }
    .logo-container {
        padding-bottom: 2vh;
        max-width: 100%;
        margin: 0 auto;
    }
    .list-of-links {
        flex-direction: column;
        padding-bottom: 2vh;
    }
    .footer-text {
        padding-bottom: 2.5vh;
    }
    .socials-container {
        margin: 0 auto; 
    }
}