* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    background: #333; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
}

ul {
    list-style-type: none;
}

code {
    font-family: Arial, Helvetica, sans-serif;
}

.btn {
    color: #fff;
    border: 1px solid #fff;
    padding: 12px 32px;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: transparent;
    cursor: pointer;
}

.btn-light {
    background: rgba(255,255,255,0.2)
}

.btn:hover {
    background: rgba(255,255,255,0.2)
}