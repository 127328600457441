.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*, p {
  color: black;
  text-decoration: none;
}

/* background image used for entire site */
 body {
  background-color: white;
  background-size: cover;
  background-position: center center;
  margin: 0;
  height: 100vh;
} 

/* media queries for setting relative font size for entire page */
@media (max-width: 1240px) {
  html {
    font-size: 1rem
  }
}
@media (max-width: 992px) {
  html {
    font-size: 0.90rem
  }
}
@media (max-width: 744px) {
  html {
    font-size: 0.80rem
  }
}

