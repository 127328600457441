.waitlist-page p, .center-text, .waitlist-heading, .athlete-text, .waitlist-page .navbar .navbar-text {
    color: white;
    text-decoration: none;
}

.waitlist-page .navbar .opti {
    color: black;
}

.waitlist-page .navbar .waitlist-button {
    color: hsla(161.44329896907217, 44.70%, 57.45%, 1.00);
    background-color: hsla(170, 0.00%, 100.00%, 1.00);
}

.waitlist-page .navbar .navbar-text-active {
    color: black;
}

.waitlist-page .navbar .nav-menu.active {
    color: black;
}

.waitlist-page .footer .footer-bottom-text {
    color: black;
}

.waitlist-page {
    background-image: url('../assets/waitlist-assets/gradient.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
}

.center-text {
    padding-top: 20vh;
    text-align: center;
}
.waitlist-heading {
    font-size: 5rem;
    font-family: Outfit;
}
@media (max-width: 370px) {
    .waitlist-heading {
        font-size: 4rem;
    }
}
.waitlist-subheading {
    font-family: Outfit;
    line-height: 3.5vh;
    margin-bottom: 4vh;
}
.form-container {
    align-items: center;
    width: 50vw;
    margin-right: auto;
    margin-left: auto;
}
.name-container {
    display: flex;
    justify-content: space-between;
}
.name-field, .email-field {
    margin-bottom: 20px;
    padding-top: 2vh;
    padding-bottom: 2vh;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    opacity: 1;
    font-family: Outfit;
    color: hsla(0, 2.27%, 11.08%, 1.00);
    font-size: 16px;
    font-weight: 400;
    text-indent: 20px;
    border: 1px solid gray;
  }

.name-field {
    width: 24.5vw;
}
@media (max-width: 450px) {
    .name-container {
        flex-direction: column 
    }
    .name-field {
        width: 50vw;
    }
}
.email-field {
    width: 50vw;
}

.athlete-container {
    font-family: Outfit;
    text-align: center;
    margin-bottom: 2vh;
}

.athlete-text {
    font-family: Outfit;
}

.athlete-select {
    margin-bottom: 15px;
    padding-top: 2.25vh;
    padding-bottom: 2.25vh;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    transition-property: opacity;
    transition-duration: 200ms;
    transition-timing-function: ease;
    color: hsla(0, 0.00%, 0.00%, 1.00);
    font-weight: 400;
    text-indent: 20px;
    width: 50vw;
    font-family: Outfit;
}

.athlete-option{
    color: black;
    font-family: Outfit;
    text-indent: 5vw;
}
.data-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.check { 
    height: auto;
    max-height: 1rem;
    margin-right: 0.3vw;
}
.data-text {
    font-size: 1rem;
} 
.submit-button {
    box-sizing: border-box;
    margin-top: 20px;
    padding-top: 10px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-left: 40px;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: #62c3a5;
    color:white;
    font-family: Outfit;
    border-style: none;
    margin-bottom: 10vh;
}

  .success-message {
    padding-top: 28px;
    padding-right: 28px;
    padding-bottom: 32px;
    padding-left: 28px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background-color: hsla(244.87309644670052, 100.00%, 97.32%, 1.00);
    color: #4a3aff;
  }
  
  .popup-container {
    align-items: center;
    width: 50vw;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
    background-color: hsla(244.87309644670052, 0.00%, 100.00%, 1.00);
    color: hsla(170.29411764705884, 28.57%, 46.67%, 1.00);
  }

  .popup-check {
    height: 8.5vh;
    width: auto;
    margin-top:4vh;
    margin-bottom:2.5vh;
  }
.waitlist-page .popup-message {
    color: black;
    padding-bottom: 4vh;
    font-family: Outfit;
    color: hsla(171.6923076923077, 28.21%, 34.84%, 1.00);
    font-size: 1.1rem;
    font-weight: 400;
}